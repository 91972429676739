<template>
  <div>
    <breadCrumb title="博客" :menuList="menuList"></breadCrumb>
    <section class="pb-4">
      <div class="container">
          <div class="card-columns">
            <div class="card mb-3 overflow-hidden shadow-sm" v-for="(item, i) in blogList" :key="i">
              <a  class="text-reset d-block" @click="toDetail(item)">
                <img :src="item.url"  class="img-fluid lazyload ">
              </a>
              <div class="p-4">
                <h2 class="fs-18 fw-600 mb-1">
                  <a  class="text-reset">
                    {{ item.title }}
                  </a>
                </h2>
                <div class="mb-2 opacity-50" v-if="item.categoryName">
                  <i>{{ item.categoryName }}</i>
                </div>
                <p class="opacity-70 mb-4">
                  {{item.desc}}
                </p>
                <a href="javascript: void(0)" class="btn btn-soft-primary">
                  查看更多
                </a>
              </div>
            </div>
          </div>
          <div class="aiz-pagination aiz-pagination-center mt-4">
            <!--分页-->
          </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: '博客',
          path: 'blog'
        },
      ],
      blogList: [
        {
          url: require('../../assets/imgs/blog.jpg'),
          title: '3 must-buy travel items for new parents',
          desc: "Whether it's a day outing or a long family holiday, ample preparation always pays off.Besides doing ample research on the transportation"
        },
        {
          url: require('../../assets/imgs/blog.jpg'),
          title: '3 must-buy travel items for new parents',
          desc: "Whether it's a day outing or a long family holiday, ample preparation always pays off.Besides doing ample research on the transportation"
        },
        {
          url: require('../../assets/imgs/blog.jpg'),
          title: '3 must-buy travel items for new parents',
          desc: "Whether it's a day outing or a long family holiday, ample preparation always pays off.Besides doing ample research on the transportation"
        },
      ]
     
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'blogDetail'
      })
    }
  }
}
</script>